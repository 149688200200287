<template>
  <div
    class="relative overflow-hidden bg-background_error bg-right-bottom bg-no-repeat pr-32"
    :class="'variant-' + variant"
    :style="variant === 'micro' ? {} : { backgroundImage: `url(${floor})` }"
  >
    <div class="ksim-container absolute">
      <img :src="randomKSim()" />
    </div>
    <div class="text-container relative flex-1">
      <h1 class="leading-none text-text_main">{{ title }}</h1>
      <template v-if="variant === 'inline'">
        <p class="text-sm text-text_static">
          We're having trouble loading things. Please
          <a href="mailto:connect.service@kongsbergdigital.com"> contact support </a>
          if the problem persists.
        </p>
      </template>
      <template v-else-if="variant === 'seaview'">
        <p class="text-5xl text-text_static">We're having trouble loading SeaView</p>
      </template>
      <template v-else-if="variant === 'radar'">
        <p class="text-5xl text-text_static">We're having trouble loading RADAR</p>
      </template>
      <template v-else-if="variant === 'ecdis'">
        <p class="text-5xl text-text_static">We're having trouble loading ECDIS</p>
      </template>
      <template v-else-if="variant === 'default'">
        <p class="mb-4 text-sm text-text_static">We're having trouble loading this instrument.</p>
        <p class="text-xs text-text_static">
          Please
          <a href="mailto:connect.service@kongsbergdigital.com"> contact support </a>
          if the problem persists.
        </p>
      </template>
      <template v-else><p class="text-sm text-text_static">The instrument didn't load</p></template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import floorDay from '../../assets/floor-day.svg?url';
import floorDusk from '../../assets/floor-dusk.svg?url';
import { randomKSim } from '../randomKSim';
import { usePalette } from '../usePalette';

const props = withDefaults(
  defineProps<{ variant: 'seaview' | 'radar' | 'ecdis' | 'default' | 'mini' | 'micro' | 'inline' }>(),
  {
    variant: 'inline',
  },
);

const title = computed(
  () =>
    ({
      seaview: "This ain't plain sailing!",
      radar: "This isn't right",
      ecdis: "This isn't right",
      default: "This isn't right",
      micro: 'Oops…',
      mini: 'Oops…',
      inline: "Something's wrong here",
    }[props.variant] ?? 'Oops…'),
);

const palette = usePalette();
const floor = computed(() => ({ day: floorDay, dusk: floorDusk }[palette.value]));
</script>

<style scoped>
a {
  @apply border-b text-feedback_text;
}

.variant-inline {
  @apply rounded bg-[length:auto_80%] py-10;
  .text-container {
    @apply ml-6;
  }
  h1 {
    @apply mb-4 text-2xl;
  }
  .ksim-container {
    @apply top-3 right-10;
    img {
      @apply h-64;
    }
  }
}

.variant-seaview,
.variant-radar,
.variant-ecdis {
  @apply bg-[length:auto_80%] py-10;
  .text-container {
    @apply ml-16;
  }
  h1 {
    @apply mb-12 mt-32 text-[5.5rem];
  }
  .ksim-container {
    @apply right-0 top-0 flex h-full -translate-x-[100%] transform items-center;
    img {
      @apply h-[85%];
    }
  }
}

.variant-default {
  @apply bg-[length:auto_80%] py-10;
  .text-container {
    @apply ml-6;
  }
  h1 {
    @apply mb-2 text-3xl;
  }
  .ksim-container {
    @apply right-0 h-[85%] translate-y-[-5%] -translate-x-[100%] transform;
    img {
      @apply h-full;
    }
  }
}

.variant-mini {
  @apply aspect-square bg-[length:auto_50%] py-10;
  .text-container {
    @apply ml-6;
  }
  h1 {
    @apply mb-2 text-2xl;
  }
  .ksim-container {
    @apply right-0 h-[120%] translate-y-[-5%] -translate-x-[20%] transform;
    img {
      @apply h-full;
    }
  }
}

.variant-micro {
  @apply aspect-micro bg-[length:auto_50%] py-6;
  .text-container {
    @apply ml-6;
  }
  h1 {
    @apply mb-2 text-2xl;
  }
  .ksim-container {
    @apply right-0 h-[190%] translate-y-[-5%] -translate-x-[35%] transform;
    img {
      @apply h-full;
    }
  }
}
</style>
