<template>
  <div class="absolute left-0 top-0 grid h-full w-full grid-cols-[auto_40%] gap-14 bg-background_tile p-10">
    <aside class="flex gap-6 overflow-hidden">
      <signi-loading-indicator
        class="flex-shrink-0"
        :start="0"
        :end="1"
        :progress="progress"
        @animation-done="animationDone = true"
      />
      <div class="flex-1 overflow-hidden">
        <div class="text-xl font-bold text-feedback_text">
          {{ ready ? 'Ready to launch' : 'Loading exercise&hellip;' }}
        </div>

        <signi-error v-if="briefError" variant="inline" />
        <div v-else class="truncate text-3xl font-bold text-text_main" :title="title">
          {{ title }}
        </div>
        <div v-if="application" class="text-xl text-text_static">{{ application }}</div>

        <button class="btn btn-primary mt-2 w-52 uppercase" :disabled="!ready" @click="$emit('start')">
          {{ ready ? 'Get started' : 'Loading&hellip;' }}
        </button>
      </div>
    </aside>
    <div class="flex flex-col overflow-hidden">
      <header class="bg-pano_modal_body px-14 pb-6 pt-10 text-2xl font-bold uppercase text-text_main">
        Exercise briefing
      </header>
      <div class="flex flex-1 flex-col overflow-hidden bg-pano_topbar_background py-4 pl-14 pr-3">
        <signi-error v-if="briefError" variant="inline"></signi-error>
        <!-- eslint-disable vue/no-v-html -->
        <section
          v-else-if="briefSections?.length == 0 && description != ''"
          class="overflow-y-auto pr-9 text-text_main"
          v-html="description"
        ></section>
        <div v-else-if="briefSections?.length > 0" class="overflow-y-auto pr-9 text-text_main">
          <section v-for="(content, index) in briefSections" :key="index">
            <template v-if="content.type === 'text'">
              <h3 class="mt-1 mb-4 text-2xl font-bold uppercase leading-9 text-text_main">{{ content.title }}</h3>
              <signi-markdown-viewer :source="content.text" />
            </template>

            <img v-else-if="content.type === 'image'" class="mt-10" :src="content.url" />
          </section>
        </div>
        <img v-else :src="emptyBrief" alt="No brief available" class="ml-[-23px] h-full" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import emptyBrief from '../../assets/emptyBrief.svg?url';
import { BriefSection } from '../../types/types';

const props = withDefaults(
  defineProps<{
    application?: string;
    briefError?: { message: string };
    briefSections: BriefSection[];
    description?: string;
    progress: number;
    title?: string;
  }>(),
  {
    application: undefined,
    briefError: undefined,
    description: undefined,
    title: '…',
    briefSections: () => [],
  },
);
defineEmits(['start']);

const animationDone = ref(false);
const loading = computed(() => props.progress < 1);
const ready = computed(() => !loading.value && animationDone);
</script>
