import { createGlobalState } from '@vueuse/core';
import { computed, reactive } from 'vue';

export const useErrorState = createGlobalState(() => {
  const state = reactive<{ message?: string }>({
    message: undefined,
  });

  return { state, error: computed(() => !!state.message) };
});
