import { useMemoize } from '@vueuse/core';

const createWebSocket = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  const url = `${protocol}${window.location.host}/ws`;
  const maxRetries = 5; // Maximum number of retry attempts
  let retryCount = 0;   // Current retry count

  const ws = new WebSocket(url);

  ws.addEventListener('open', () => {
    console.log('WebSocket connection opened');
    retryCount = 0; // Reset retry count on successful connection
  });

  ws.addEventListener('close', (event) => {
    console.log('WebSocket connection closed with code:', event.code);
  });

  ws.addEventListener('error', (error) => {
    console.error(`WebSocket error (Retry Attempt #${retryCount + 1}):`, error);
     // Retry only if retry count is less than maxRetries
     if (retryCount < maxRetries) {
      retryCount++;
      setTimeout(createWebSocket, 1000); // Retry after 1 second
    }
  });

  return ws;
};


export const useWebSocketClient = useMemoize(() => {
  const wsClient = createWebSocket();
  return { wsClient };
});
