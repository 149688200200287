<template>
  <div>
    <div
      class="fixed left-0 top-0 flex h-full w-full bg-background_error bg-[length:auto_80vh] bg-right-bottom bg-no-repeat"
      :style="{ backgroundImage: `url(${floor})` }"
    >
      <div class="relative ml-[8.25rem] mt-[22vh] flex-1">
        <h1 class="mb-12 mt-10 text-[5.5rem] leading-none text-text_main">Oops&hellip;</h1>
        <div class="mb-8 text-[2.8125rem] text-text_static">We're having trouble loading the simulator right now.</div>
        <div class="text-3xl text-text_static">
          Please try <a href="#" @click.prevent="reload">refreshing the page</a> or
          <a :href="`mailto:connect.service@kongsbergdigital.com${message ? `?body=${encodeURI(emailMessage)}` : ''}`">
            contact support
          </a>
          if the problem persists.
        </div>
      </div>
      <div class="flex-shrink-0 pr-[22vh]">
        <img :src="randomKSim()" class="h-full py-[5vh]" />
      </div>
    </div>

    <div v-if="message" class="absolute bottom-0 flex w-full justify-center">
      <button class="mb-8 text-xl text-text_static" @click="showErrorDetails = true">
        <signi-icon class="align-sub" name="OpenIcon" /> Show me the techy details
      </button>
    </div>

    <signi-error-modal v-if="message" :details="message" :open="showErrorDetails" @close="showErrorDetails = false" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import floorDay from '../../assets/floor-day.svg?url';
import floorDusk from '../../assets/floor-dusk.svg?url';
import { randomKSim } from '../randomKSim';
import { usePalette } from '../usePalette';

const props = defineProps<{ message?: string }>();

const emailMessage = computed(() => {
  return `There was an error running ERS. These are the details:
  
${props.message}`;
});

const palette = usePalette();
const floor = computed(() => ({ day: floorDay, dusk: floorDusk }[palette.value]));

const showErrorDetails = ref(false);

const reload = () => location.reload();
</script>

<style scoped>
a {
  @apply border-b text-feedback_text;
}
</style>
