import { createGlobalState } from '@vueuse/core';
import { reactive } from 'vue';

export const useLoadingState = createGlobalState(() => {
  const state = reactive<{ loading: boolean }>({
    loading: false,
  });

  return { state };
});
