import { v4 as uuidv4 } from 'uuid';
import { CloudEvent, Signal, SignalData } from './types';

const createControlSignal = (signal: Signal): CloudEvent<SignalData> => ({
  specversion: '1.0',
  id: uuidv4(),
  source: 'urn:com:ksimconnect:ers:web',
  type: 'ksimconnect.cloudsimulationruntime.bootstrapper.messages.applycontrolsignal',
  datacontenttype: 'application/json',
  data: { signal },
});
export const createEvent = (signal: Signal) => JSON.stringify(createControlSignal(signal));
