import RFB from '@novnc/novnc';
import NoVncClient from '@novnc/novnc/core/rfb';
import { initLogging } from '@novnc/novnc/core/util/logging';
import { useSessionStorage } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { ref } from 'vue';
import { useErrorState } from './useErrorState';
import { useLoadingState } from './useLoadingState';

initLogging('debug');

function randomId() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

/**
 * @example
 * const container = ref<HTMLDivElement>(null!);
 * onMounted(() => {
 *   const vnc = useNoVNC(container.value);
 * });
 */
export function useNoVNC(el: HTMLDivElement, path: string) {
  const desktopName = ref<string>();
  const { state: errorState } = useErrorState();
  const { state: loadingState } = useLoadingState();

  const keys = {
    staticId: 'com:ksimconnect:ers:staticId',
    sessionId: 'com:ksimconnect:ers:sessionId',
  };

  const cookies = useCookies([keys.sessionId, keys.staticId]);
  if (!cookies.get(keys.staticId)) {
    cookies.set(keys.staticId, randomId());
  }
  if (!cookies.get(keys.sessionId)) {
    cookies.set(keys.sessionId, randomId());
  }
  const tabId = useSessionStorage('tabId', randomId());

  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  const query = new URLSearchParams({
    staticId: cookies.get(keys.staticId),
    sessionId: cookies.get(keys.sessionId),
    tabId: tabId.value,
  });

  const url = `${protocol}${window.location.host}${[path]}?${query.toString()}`;

  const rfb: NoVncClient = new RFB(el, url, { wsProtocols: 'lws-procsee-rfb' });
  loadingState.loading = true;

  rfb.addEventListener('connect', () => {
    console.log('connected');
    loadingState.loading = false;
  });
  rfb.addEventListener('error', () => {
    errorState.message = 'Some error occurred';
    loadingState.loading = false;
  });
  rfb.addEventListener('disconnect', (e: CustomEvent<{ clean: boolean }>) => {
    if (e.detail.clean) {
      console.log('disconnected successfully');
    } else {
      loadingState.loading = false;
      errorState.message = 'Something went wrong, connection is closed';
    }
  });
  rfb.addEventListener('credentialsrequired', () => {
    console.log('credentials required');
  });
  rfb.addEventListener('desktopname', (e: CustomEvent<{ name: string }>) => {
    desktopName.value = e.detail.name;
  });

  rfb.showDotCursor = true;
  rfb.resizeSession = true;

  return rfb;
}
