// @deprecated use UseLocalStorage from @vueuse
import { isReactive, watch } from 'vue';

type Reactive = Record<string, unknown>;

let cache: Record<string, Reactive>;

function monitorStorageEvents() {
  cache = {};

  window.addEventListener('storage', (e) => {
    if (e.key === null || e.newValue === null || e.storageArea === null) {
      return;
    }

    const cached = cache[e.key];

    if (cached === undefined) {
      return;
    }

    rehydrate(e.key, cached, e.storageArea);
  });
}

export function getState(storage: Storage, key: string): Reactive | null {
  const stateJSON = storage.getItem(key);
  if (stateJSON === null) {
    return null;
  }

  try {
    return JSON.parse(stateJSON);
  } catch {
    // something is wrong with session storage item, clear it
    storage.removeItem(key);
    return null;
  }
}

function rehydrate(key: string, state: Reactive, storage: Storage) {
  const stored = getState(storage, key);
  if (stored !== null) {
    Object.entries(stored).forEach(([prop, value]) => {
      (state as Reactive)[prop] = value;
    });
  }
}

function setState(storage: Storage, key: string, state: Reactive): void {
  storage.setItem(key, JSON.stringify(state));
}

/**
 * Make any Vue reactive object persistent. The initial state and any subsequent changes are persisted using the Web Storage API.
 *
 * @param {string} key The key under which the state is persisted.
 * @param {object} state The state object that will be persisted.
 * @param {Storage} storage The storage object that will be used to persist, defaults to `sessionStorage`.
 * @returns {object} The state that was passed in.
 */
export function persist<T extends Reactive>(key: string, state: T, storage: Storage = sessionStorage): T {
  if (!isReactive(state)) {
    throw Error('persist() only works on a reactive object');
  }

  if (cache === undefined) {
    monitorStorageEvents();
  }

  const cached = cache[key] as T;

  if (cached !== undefined) {
    return cached;
  }

  rehydrate(key, state, storage);

  cache[key] = state;

  watch(state, () => setState(storage, key, state), { immediate: true });

  return state;
}
