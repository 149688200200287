import { computed, reactive, readonly, watch } from 'vue';
import b2c, { TokenResponse } from './b2c';
import { Features } from './types';
import { persist } from './utils';

type HashParameters = {
  code?: string;
  id_token?: string;
  access_token?: string;
};

type TokenStore = {
  access_token?: string;
  config?: {
    issuer: string;
    tenantId: string;
    policy: string;
    clientId: string;
  };
  refresh_token?: string;
};

type State = {
  accessToken?: JsonWebToken;
  accessTokenExpirationHandle?: number;
  accessTokenRefreshHandle?: number;
};

type JsonWebToken = {
  expiresAt: Date;
  notBefore: Date;
  issuer: URL;
  classReference: string;
  audience: string;
  tenantId: string;
  nonce: string;
  raw: string;
};

const tokenRefreshMargin = 10 * 60 * 1000;

const decode = (token: string | undefined): JsonWebToken | undefined => {
  if (token === undefined) {
    return undefined;
  }

  const { payload } = splitToken(token);

  const decoded: Record<string, string> = JSON.parse(atob(payload));

  const expiresAt = new Date(0);
  const notBefore = new Date(0);

  expiresAt.setUTCSeconds(Number.parseInt(decoded['exp']));
  notBefore.setUTCSeconds(Number.parseInt(decoded['nbf']));

  return {
    expiresAt: expiresAt,
    notBefore: notBefore,
    issuer: new URL(decoded['iss']),
    classReference: decoded['acr'],
    audience: decoded['aud'],
    tenantId: decoded['tid'],
    nonce: decoded['nonce'],
    raw: token,
  };
};

const splitToken = (token: string): { header: string; payload: string; signature: string } => {
  const parts = token.split('.');

  return {
    header: parts[0],
    payload: parts[1],
    signature: parts[2],
  };
};

const storageKey = 'authentication.v1';
const store: TokenStore = persist(storageKey, reactive({}), localStorage);

const internalState: State = reactive({});

const jitter = (max: number): number => Math.random() * max;

const storeUpdated = (store: TokenStore) => {
  internalState.accessToken = decode(store.access_token);
  monitorTokenExpiration();
};

const monitorTokenExpiration = () => {
  if (internalState.accessToken !== undefined) {
    internalState.accessTokenExpirationHandle = monitorExpiration(
      internalState.accessToken,
      () => (store.access_token = undefined),
      internalState.accessTokenExpirationHandle,
    );

    internalState.accessTokenRefreshHandle = monitorExpiration(
      internalState.accessToken,
      tryRefreshToken,
      internalState.accessTokenRefreshHandle,
      tokenRefreshMargin - jitter(tokenRefreshMargin * 0.1), //subtract a random number of milliseconds with a maximum of 10% of the original margin to prevent multiple browser windows/tabs from making concurrent refresh calls
    );
  }
};

const tryRefreshToken = async (): Promise<void> => {
  if (store.config === undefined || store.refresh_token === undefined) {
    //we can't refresh the access token, so we let it expire instead.
    return;
  }

  if (internalState.accessToken !== undefined) {
    const expiresIn = internalState.accessToken.expiresAt.getTime() - Date.now();

    if (expiresIn > tokenRefreshMargin) {
      /*
       * The token is not yet close enough to expiring to warrant a refresh. It may have been refreshed by another browser windows/tab
       * So we reset the expiration timers which will schedule a new refresh right before the token is about to expire
       */
      monitorTokenExpiration();
      return;
    }
  }

  try {
    const tokens = await b2c.refresh(
      store.config.issuer,
      store.config.tenantId,
      store.config.policy,
      store.config.clientId,
      store.refresh_token,
    );

    setStore(tokens);
  } catch {
    if (internalState.accessToken !== undefined && internalState.accessToken.expiresAt.getTime() > Date.now()) {
      /*
       * We failed to refresh the token, but another browser windows/tab might have already refreshed it.
       * So we reset the expiration timers this will either:
       * - cause another refresh to happen if the token is about to expire
       * - schedule a new refresh right before the new token is about to expire
       */
      monitorTokenExpiration();
    }
  }
};

const monitorExpiration = (
  token: JsonWebToken,
  callback: () => void,
  existingHandle?: number,
  margin?: number,
): number | undefined => {
  if (existingHandle !== undefined) {
    window.clearTimeout(existingHandle);
  }

  let expiresIn = token.expiresAt.getTime() - Date.now() - (margin ?? 0);

  if (expiresIn < 0) {
    expiresIn = 0;
  }

  return window.setTimeout(callback, expiresIn);
};

/*
 * In the hybrid OAUTH flow both a 'code' and 'id_token' are provided on the URL hash.
 */
const tryHandleHybridFlow = async (hash: HashParameters): Promise<boolean> => {
  if (hash.code === undefined || hash.id_token === undefined) {
    return false;
  }

  //clear local storage because a new OAUTH flow was started
  localStorage.removeItem(storageKey);

  const idToken = decode(hash.id_token);

  if (idToken === undefined) {
    return false;
  }

  /*
   * Normally, when the web application initiates the login flow, the web application generates and stores
   * the code_verifier and generates the code_challenge based on the code_verifier.
   * However, in our case, the web application does not initiate the login flow and the code_verifier and code_challenge
   * are generated server-side so we "cheat" by using the nonce as the code_verifier.
   *
   * Because the nonce is part of the ID token it can be intercepted and thus it breaks the additional security added
   * by the PKCE flow. However B2C seems to enforce the use of PKCE when using the auth code flow so we have to work
   * around that. Using the auth code flow without PKCE would be equally secure but B2C does not allow that.
   */
  const codeVerifier = idToken.nonce;

  const tokens = await b2c.redeem(
    idToken.issuer.toString(),
    idToken.tenantId,
    idToken.classReference,
    idToken.audience,
    hash.code,
    codeVerifier,
  );

  setStore(tokens);
  return true;
};

/*
 * In the hybrid OAUTH implicit flow an 'access_token' is provided on the URL hash.
 * An 'id_token' is usually provided as well but we don't need that here, so we ignore it.
 */
const tryHandleImplicitFlow = (hash: HashParameters): boolean => {
  if (hash.access_token === undefined) {
    return false;
  }

  //clear local storage because a new OAUTH flow was started
  localStorage.removeItem(storageKey);

  setStore({
    access_token: hash.access_token,
  });

  return true;
};

const setStore = async (tokens: TokenResponse) => {
  store.access_token = tokens?.access_token;
  store.refresh_token = tokens?.refresh_token;

  const idToken = decode(tokens?.id_token);

  if (idToken !== undefined) {
    store.config = {
      clientId: idToken.audience,
      issuer: idToken.issuer.toString(),
      policy: idToken.classReference,
      tenantId: idToken.tenantId,
    };
  } else {
    store.config = undefined;
  }
};

const parse = (hash: string): HashParameters => {
  if (hash.startsWith('#')) {
    hash = hash.substr(1);
  }

  const matches: Record<string, string> = {};
  const hashParams = new URLSearchParams(hash);

  for (const [key, value] of hashParams) {
    matches[key] = value;
  }

  window.location.hash = '';
  return matches;
};

const featureStore: Features = persist('features', reactive({}), localStorage);

export const isAuthenticated = computed(
  () => !!featureStore.bypassAuth || (internalState.accessToken && internalState.accessToken?.expiresAt > new Date()),
);

export const state = readonly(internalState);

export async function handleRedirect(): Promise<void> {
  const hash = parse(window.location.hash);
  if (!(await tryHandleHybridFlow(hash)) && !tryHandleImplicitFlow(hash)) {
    //not a redirect flow
  }

  watch(store, () => storeUpdated(store), { immediate: true });

  monitorTokenExpiration();
}
