import ksim1 from '../assets/k-sims/1.svg?url';
import ksim10 from '../assets/k-sims/10.svg?url';
import ksim11 from '../assets/k-sims/11.svg?url';
import ksim12 from '../assets/k-sims/12.svg?url';
import ksim13 from '../assets/k-sims/13.svg?url';
import ksim14 from '../assets/k-sims/14.svg?url';
import ksim15 from '../assets/k-sims/15.svg?url';
import ksim2 from '../assets/k-sims/2.svg?url';
import ksim3 from '../assets/k-sims/3.svg?url';
import ksim4 from '../assets/k-sims/4.svg?url';
import ksim5 from '../assets/k-sims/5.svg?url';
import ksim6 from '../assets/k-sims/6.svg?url';
import ksim7 from '../assets/k-sims/7.svg?url';
import ksim8 from '../assets/k-sims/8.svg?url';
import ksim9 from '../assets/k-sims/9.svg?url';

export const randomKSim = () =>
  [ksim1, ksim2, ksim3, ksim4, ksim5, ksim6, ksim7, ksim8, ksim9, ksim10, ksim11, ksim12, ksim13, ksim14, ksim15][
    Math.floor(Math.random() * 15)
  ];
