<template>
  <div ref="container" class="absolute top-0 left-0 h-full w-full"></div>

  <hui-disclosure as="nav" :class="['pointer-events-none relative flex h-full p-6', positionClasses]">
    <hui-disclosure-button class="btn btn-icon btn-fab pointer-events-auto" @click="panelOpen = !panelOpen">
      <signi-icon name="MenuIcon" />
    </hui-disclosure-button>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div v-show="panelOpen" class="pointer-events-auto">
        <hui-disclosure-panel static>
          <ers-panel
            v-model:position="panelPosition"
            v-model:view="viewId"
            :state="state"
            :time="time"
            :views="views"
            @stop="showStopDialog = true"
          ></ers-panel>
        </hui-disclosure-panel>
      </div>
    </transition>
  </hui-disclosure>
  <signi-stopping-modal :open="showStopDialog" @cancel="showStopDialog = false" @confirm="stop" />
  <signi-stopped-modal :exercise-name="exerciseName" :open="stopped" @close="close" />
</template>

<script lang="ts" setup>
import NoVncClient from '@novnc/novnc/core/rfb';
import { computed, ref, watchEffect } from 'vue';
import { createEvent } from '@/cloudEvents';
import { CloudEvent, State, StateData, TimeData, View } from '@/types';
import { useNoVNC } from '@/useNoVNC';
import { useWebSocketClient } from '@/useWebSocketClient';

withDefaults(defineProps<{ exerciseName?: string }>(), { exerciseName: '&hellip;' });

const panelOpen = ref(false);
const panelPosition = ref('Bottom right');
const views = ref<View[]>();
const viewId = ref<string>('1');

const container = ref<HTMLDivElement>(null!);
const view = computed(() => views.value?.find((v) => v.id === viewId.value));
let noVncClient: NoVncClient | undefined;

const showStopDialog = ref(false);
const { wsClient } = useWebSocketClient();

const time = ref<number>(0);
const state = ref<State>('paused');
const stopped = computed(() => state.value === 'stopped');

wsClient.onmessage = (event: MessageEvent<string>) => {
  const message: CloudEvent<StateData | TimeData> = JSON.parse(event.data);
  if ('state' in message.data) {
    state.value = message.data.state;
  } else if ('time' in message.data) {
    time.value = message.data.time;
  }
};

const stop = () => {
  showStopDialog.value = false;
  wsClient.send(createEvent('stop'));
};

const close = () => window.close();

watchEffect(() => {
  if (container.value && view.value) {
    noVncClient?.disconnect();
    if (import.meta.env.PROD) {
      noVncClient = useNoVNC(container.value, view.value.path);
    }
  }
});

fetch('/api/views')
  .then((res) => res.json())
  .then((content) => (views.value = content));

const positionClasses = computed(() => {
  const classes = [];
  if (panelPosition.value.match(/top/i)) {
    classes.push('items-start');
  }
  if (panelPosition.value.match(/bottom/i)) {
    classes.push('items-end');
  }
  if (panelPosition.value.match(/left/i)) {
    classes.push('justify-[start] [&>button]:mr-6');
  }
  if (panelPosition.value.match(/right/i)) {
    classes.push('justify-[end] [&>button]:ml-6 flex-row-reverse');
  }
  return classes;
});
</script>
