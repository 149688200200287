import { Ref, ref } from 'vue';

type Coordinate = { x: number; y: number };

export const useDraggable = (): {
  dragMouseDown: (_: MouseEvent) => void;
  dragOffset: Ref<Coordinate>;
} => {
  let start: Partial<Coordinate> = { x: undefined, y: undefined };
  const offset = ref<Coordinate>({ x: 0, y: 0 });

  // Get the mouse cursor position on drag start
  const dragMouseDown = (event: MouseEvent) => {
    event.preventDefault();

    start = {
      x: event.clientX - offset.value.x,
      y: event.clientY - offset.value.y,
    };
    document.addEventListener('mousemove', elementDrag);
    document.addEventListener('mouseup', closeDragElement);
  };

  const elementDrag = (event: MouseEvent) => {
    event.preventDefault();

    offset.value = {
      x: event.clientX - (start.x ?? 0),
      y: event.clientY - (start.y ?? 0),
    };
  };

  const closeDragElement = () => {
    document.removeEventListener('mousemove', elementDrag);
    document.removeEventListener('mouseup', closeDragElement);
  };

  return {
    dragMouseDown,
    dragOffset: offset,
  };
};
