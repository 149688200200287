import './index.css';
import '@signi/common/assets/palettes.css';

import { createApp } from 'vue';
import { addPalette } from '@signi/common';
import { handleRedirect } from '@signi/magic-link-authentication';
import App from '@/App.vue';

window.addEventListener('load', async () => {
  if (import.meta.env.DEV) {
    const { worker } = await import('./mocks/browser');
    worker.start({ onUnhandledRequest: 'bypass' });

    createApp(App).mount('#app');
    addPalette('day');
  } else {
    await handleRedirect();
    createApp(App).mount('#app');
    addPalette('day');
  }
});