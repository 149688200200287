export type TokenResponse = {
  access_token?: string;
  id_token?: string;
  refresh_token?: string;
};

const redeemToken = async (
  issuer: string,
  tenantId: string,
  policy: string,
  clientId: string,
  code: string,
  codeVerifier: string,
): Promise<TokenResponse> =>
  postToken(issuer, tenantId, policy, clientId, (data) => {
    data.append('grant_type', 'authorization_code');
    data.append('code', code);
    data.append('code_verifier', codeVerifier);
  });

const refreshToken = async (
  issuer: string,
  tenantId: string,
  policy: string,
  clientId: string,
  refreshToken: string,
): Promise<TokenResponse> =>
  postToken(issuer, tenantId, policy, clientId, (data) => {
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', refreshToken);
  });

const postToken = async (
  issuer: string,
  tenantId: string,
  policy: string,
  clientId: string,
  append: (data: FormData) => void,
  tries = 5,
): Promise<TokenResponse> => {
  const issuerUrl = new URL(issuer);
  const tokenEndpoint = new URL(`/${tenantId}/${policy}/oauth2/v2.0/token`, issuerUrl.origin);
  let redirect = new URL(window.location.pathname, window.location.origin).toString();

  if (redirect.endsWith('/')) {
    redirect = redirect.substring(0, redirect.length - 1);
  }

  const data = new FormData();
  data.append('client_id', clientId);
  data.append('scope', `${clientId} openid`);
  data.append('redirect_uri', redirect);

  append(data);

  try {
    const response = await fetch(tokenEndpoint.toString(), {
      method: 'post',
      body: data,
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.status >= 400 && response.status !== 404 && response.status < 500) {
      /*
       * The request is not valid so we are not able to get any tokens.
       * We make an exception for HTTP 404 because that can also be the result of a networking error
       */
      return {};
    }

    const body: Record<string, string> = await response.json();

    return {
      id_token: body['id_token'],
      access_token: body['access_token'],
      refresh_token: body['refresh_token'],
    };
  } catch (error) {
    if (tries > 0) {
      tries = tries - 1;
      await delay(5000);
      return await postToken(issuer, tenantId, policy, clientId, append, tries);
    } else {
      throw error;
    }
  }
};

const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  redeem: redeemToken,
  refresh: refreshToken,
};
