export function addPalette(theme = 'dusk') {
  // Add dusk palette using JS, else Rollup will remove the <link> element from index.html during build
  // TODO: implement palette switching
  const el = document.createElement('link');
  el.id = 'palette-sheet';
  el.rel = 'stylesheet';
  el.type = 'text/css';
  el.href = `${import.meta.env.BASE_URL}palette-${theme}.css`;
  document.head.appendChild(el);
}
