<template>
  <signi-modal-transition>
    <div
      v-if="open"
      ref="container"
      class="fixed top-0 left-0 z-[1100] h-full min-w-full"
      :class="{
        'bg-background_overlay': backdrop,
        'pointer-events-none': !backdrop,
        'grid place-items-center': !initialPosition,
      }"
      role="dialog"
      @click="onContainerClick"
    >
      <div
        class="modal"
        :class="{
          'bg-pano_modal_body': !transparentBackground,
          absolute: initialPosition,
        }"
        :style="{
          transform: 'translate(' + dragOffset.x + 'px, ' + dragOffset.y + 'px)',
        }"
      >
        <div
          v-if="$slots.header"
          class="modal-header"
          :class="{
            'active:cursor:grabbing cursor-grab': draggable,
            'border-b border-background_tile_border bg-background_tile ': !transparentBackground,
          }"
          v-on="draggable ? { mousedown: dragMouseDown } : {}"
        >
          <slot name="header"></slot>

          <div class="flex-1" />

          <button class="ml-2" title="Close" type="button" @click="$emit('close')">
            <signi-icon name="CloseIcon" />
          </button>
        </div>

        <div :class="{ 'modal-content': contentScrollEnabled }"><slot></slot></div>
      </div>
    </div>
  </signi-modal-transition>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue';
import { useDraggable } from './draggable';
import { ModalConfig } from './modal';


const props = withDefaults(
  defineProps<{
    config?: ModalConfig;
    open?: boolean;
  }>(),
  {
    config: () => ({
      backdrop: true,
      draggable: true,
      initialPosition: undefined,
      rememberPosition: false,
      transparentBackground: false,
      contentScrollEnabled: true,
    }),
    open: false,
  },
);
const emit = defineEmits(['confirm', 'close']);

const {
  backdrop = ref(true),
  draggable = ref(true),
  initialPosition = ref(undefined),
  rememberPosition = ref(false),
  transparentBackground = ref(false),
  contentScrollEnabled = ref(true),
} = toRefs(reactive(props.config));

const container = ref<HTMLDivElement | null>(null);

const { dragMouseDown, dragOffset } = useDraggable();

if (!rememberPosition.value) {
  watch(
    () => props.open,
    (open) => {
      if (open) {
        dragOffset.value = initialPosition.value || { x: 0, y: 0 };
      }
    },
  );
} else if (initialPosition.value) {
  dragOffset.value = initialPosition.value;
}

const onContainerClick = (e: Event) => {
  if (e.target === container.value) {
    emit('close');
  }
};

const escClose = (e: KeyboardEvent) => e.code === 'Escape' && emit('close');
onMounted(() => {
  document.addEventListener('keydown', escClose);
});
onUnmounted(() => {
  document.removeEventListener('keydown', escClose);
});
</script>

<style scoped>
.modal {
  @apply pointer-events-auto rounded-lg drop-shadow-xl;
}
.modal-header {
  @apply flex items-center rounded-t-lg px-3 py-4 text-text_main;
}
.modal-content {
  @apply overflow-y-auto;
  max-height: calc(min(100vh, 800px) - 7.5625rem);
}
</style>
