<template>
  <div class="w-[624px] rounded bg-pano_modal_body drop-shadow-lg">
    <div class="mx-4 grid grid-cols-2 space-x-4 pt-8 pb-4">
      <div>
        <h2 class="title">View</h2>
        <ul class="list">
          <li v-for="v in views" :key="'view' + v.id">
            <input
              :id="'view' + v.id"
              :checked="v.id === view"
              :model-value="view"
              name="view"
              type="radio"
              :value="v.id"
              @input="$emit('update:view', v.id)"
            />
            <label :for="'view' + v.id">
              <signi-icon name="CheckIcon" class="text-feedback_text" />
              {{ v.name }}
            </label>
          </li>
        </ul>
      </div>
      <div>
        <h2 class="title">Menu position</h2>
        <ul class="list">
          <li v-for="p in positions" :key="'position' + p">
            <input
              :id="'position' + p"
              :checked="position === p"
              name="position"
              type="radio"
              :value="p"
              @input="$emit('update:position', p)"
            />
            <label :for="'position' + p">
              <signi-icon name="CheckIcon" class="text-feedback_text" />
              {{ p }}
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="relative h-[130px] px-4 pb-3 pt-[30px]">
      <img :src="wave" class="absolute bottom-0 left-0 h-full w-full rounded-b" />
      <div class="relative flex items-center">
        <img :src="logo" class="mr-10 w-[88px]" />
        <ers-exercise-controls class="mr-6" :state="state" @play="play" @pause="pause" @stop="$emit('stop')" />
        <span class="text-button_background-default">{{ timeFormatted }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatDuration, intervalToDuration } from 'date-fns';
import { computed } from 'vue';
import { createEvent } from '@/cloudEvents';
import { State, View } from '@/types';
import { useWebSocketClient } from '@/useWebSocketClient';
import logo from '../assets/logo.svg?url';
import wave from '../assets/wave.svg?url';

const props = withDefaults(
  defineProps<{
    position: 'Top left' | 'Top right' | 'Bottom left' | 'Bottom right';
    state?: State;
    time?: number;
    view: string;
    views?: View[];
  }>(),
  { state: 'paused', time: 0, views: () => [] },
);
defineEmits(['stop', 'update:position', 'update:view']);

const { wsClient } = useWebSocketClient();

const play = () => {
  wsClient.send(createEvent('play'));
};
const pause = () => {
  wsClient.send(createEvent('pause'));
};

const zeroPad = (num: number) => String(num).padStart(2, '0');
const timeFormatted = computed(() =>
  formatDuration(intervalToDuration({ start: 0, end: props.time * 1000 }), {
    delimiter: ':',
    zero: true,
    format: ['hours', 'minutes', 'seconds'],
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  }),
);
const positions = ['Top left', 'Top right', 'Bottom left', 'Bottom right'];
</script>

<style scoped>
.title {
  @apply mb-4 ml-7 text-xs uppercase text-text_static;
}
.list {
  @apply text-sm leading-[2.25];
}
input {
  @apply absolute opacity-0;
}
label {
  @apply cursor-pointer;
}
input:not(:checked) + label {
  @apply pl-7;
  svg {
    @apply hidden;
  }
}
</style>
